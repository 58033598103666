import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip,
  Legend,
  Label
} from 'recharts';
import domtoimage from 'dom-to-image';
import fileDownload from 'js-file-download';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { FaCamera } from 'react-icons/fa';

import { getXAxisTicks }  from '../utils';

const Chart = ({ 
  data, 
  title,
  unit=' ',
  titleAlignment, 
  width, 
  height, 
  boldLabel, 
  fileName, 
  showCloseBtn, 
  onClose
}) => {
  const marginLeft = 275;
  const marginTop = 50;

  const margin = {
    top: titleAlignment === 'top' ? marginTop : 5,
    left: titleAlignment === 'left' ? marginLeft : 0,
    bottom: 0,
    right: 0
  };

  const labelClass = classNames('chart-label', {
    'font-weight-bold': boldLabel
  });

  const { t } = useTranslation();

  const variables = data.map(v => v.variableValue);
  const maxVal = Math.max(...variables);
  const minVal = Math.min(...variables);

  const xAxisTicks = getXAxisTicks(data);

  const chartRef = React.useRef();

  const yAxisTickFormat = (num) => {
    const compactFormat = new Intl.NumberFormat('en', { notation: 'compact' });
    
    if (maxVal - minVal <= 1) {
      return num.toFixed(4);
    }

    return compactFormat.format(num);
  };

  const handleDownload = async () => {
    const blob = await domtoimage.toBlob(chartRef.current, { bgcolor: '#fff' });
    fileDownload(blob, `${fileName}.png`);
  };

  const renderLegendText = () => {
    return <span style={{ position: "absolute", right: 0, top: "-15px" }}>time [s]</span>;
  };

  return (
    <div className='chart-wrapper'>
      <button 
        className='download-chart-btn' 
        onClick={handleDownload} 
        title={t('download_chart_button_label')}
      >
        <FaCamera className='camera-icon' />
      </button>
      {showCloseBtn ? (
        <Button 
          color='link' 
          className='btn-bg-image remove-chart-btn btn-remove' 
          onClick={onClose}
          title={t('remove_chart_button_label')}
        >
        </Button>
      ) : (
        null
      )}
      <div ref={chartRef}>
        <LineChart width={width} height={height} data={data} margin={margin}>
          <XAxis 
            type='number' 
            dataKey='time' 
            tick={{ fontSize: 12 }} 
            ticks={xAxisTicks} 
            padding={{ left: 5, right: 5 }} 
            domain={[ 'dataMin', 'dataMax' ]} 
          >
            {titleAlignment === 'top' ? (
              <Label className={labelClass} value={`${title} [${unit}]`} position='insideBottom' dy={-height + marginTop/2} />
            ) : (
              <Label className={labelClass} value={`${title} [${unit}]`} position='insideLeft' dy={-height/2} dx={-marginLeft} />
            )}
          </XAxis>
          { data && data.length && <YAxis
            type='number' 
            dataKey={(v) => Number(v.variableValue)} 
            tickFormatter={yAxisTickFormat} 
            interval={0}
            tick={{ fontSize: 12 }}
            tickMargin={5}
            padding={{ top: 5, bottom: 5 }}
          /> }
          <CartesianGrid strokeDasharray='3 3' />
          <Tooltip />
          <Legend content={renderLegendText} />
          { data && data.length && <Line
            type='linear' 
            dataKey='variableValue' 
            stroke='#3071b3' 
            dot={{ fill: '#3071b3', strokeWidth: 0.5 }}
          /> }
        </LineChart>
      </div>
    </div>
  );
};

Chart.defaultProps = {
  titleAlignment: 'left',
  width: 720,
  height: 140,
  boldLabel: false,
  showCloseBtn: false
};

Chart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    time: PropTypes.number.isRequired,
    variableValue: PropTypes.string.isRequired
  })).isRequired,
  title: PropTypes.string.isRequired,
  unit: PropTypes.string,
  titleAlignment: PropTypes.oneOf([ 'left', 'top' ]),
  width: PropTypes.number,
  height: PropTypes.number,
  boldLabel: PropTypes.bool,
  fileName: PropTypes.string.isRequired,
  showCloseBtn: PropTypes.bool,
  onClose: PropTypes.func,
};

export default memo(Chart);
