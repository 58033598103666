import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Alert
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import ExperimentVariableContainer from './experimentVariableContainer';
import Loading from '../../main/components/loading';
import { getVariableNameFromIdsField, getVariableUnitFromIdsField } from '../utils';

const FairDataModal = ({ isOpen, onToggle, variables }) => {
  const { t } = useTranslation();
  const fairDataLoading = useSelector((state) => state.experimentOverview.get('fairData').get('loading'));
  const fairDataError = useSelector((state) => state.experimentOverview.get('fairData').get('error'));
  const fairData = useSelector((state) => state.experimentOverview.get('fairData').get('data'));

  return (
    <Modal 
      isOpen={isOpen} 
      toggle={onToggle} 
      size='xl' 
      className='fair-data-modal'
    >
      <ModalHeader toggle={onToggle}>{t('fair_data_modal.header')}</ModalHeader>
      <ModalBody>
        <section>
          {fairDataLoading && (
            <div className='d-flex align-items-center justify-content-center'>
              <Loading />
            </div>
          )}
          {fairDataError && (
            <Alert color='danger'>{t('fair_data_modal.api_error')}</Alert>
          )}
          {fairData.length === 0 ? (
            <Alert color='warning'>{t('fair_data_modal.data_not_found')}</Alert>
          ) : (
            fairData.map((item) => (
              <ExperimentVariableContainer 
                key={item.idsField}
                idsField={item.idsField}
                variableType={item.variableType}
                variableName={getVariableNameFromIdsField(variables, item.idsField) || item.idsField}
                variableUnit={getVariableUnitFromIdsField(variables, item.idsField) || ' '}
                variableTimeDependent={item.variableTimeDependent}
                groupedEntryData={item.groupedEntryData}
                idsTypeId={item.idsTypeId}
              />
            ))
          )}
        </section>
      </ModalBody>
      <ModalFooter>
        <Button 
          type='button' 
          onClick={onToggle}
        >
          {t('fair_data_modal.close_button')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

FairDataModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  variables: PropTypes.object,
  onToggle: PropTypes.func.isRequired
};

export default FairDataModal;
