import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getFileName } from '../utils';
import Chart from './chart';
import ValuesTable from './valuesTable';
import { idsTypeIds } from '../constants';

const ExperimentVariableContainer = ({ 
  idsField,
  variableType,
  variableName,
  variableUnit,
  variableTimeDependent,
  groupedEntryData,
  showEmpty,
  idsTypeId,
}) => {
  const experimentInfo = useSelector((state) => state.experimentOverview.get('experimentInfo').get('data'));
  const { shot, run, machine } = experimentInfo;

  const { t } = useTranslation();

  if (variableTimeDependent === 0) {
    const value = groupedEntryData[0]?.variableValue;

    return (((value !== "") || showEmpty) &&
      <>
        <div>
          <p>{idsField}</p>
          <p>{t('variables_container_value_label')}:{' '}
            <pre>{ value || t('variable_value_empty') }</pre>
          </p>
        </div>
        <hr />
      </>
    );
  }

  return (
    <>
      <Row>
        <Col md='6'>
          <span>{idsField}</span>
          <ValuesTable groupedEntryData={groupedEntryData} />
        </Col>
        { idsTypeId !== idsTypeIds.pedestal ? <Col md='6'>
          <Chart 
            data={groupedEntryData} 
            title={variableName || idsField}
            unit={variableUnit}
            titleAlignment='top' 
            width={519}
            height={260}
            fileName={getFileName(idsField, shot, run, machine)} 
          />
        </Col> : null }
      </Row>
      <hr />
    </>
  );
};

ExperimentVariableContainer.propTypes = {
  idsField: PropTypes.string.isRequired,
  variableType: PropTypes.number.isRequired,
  variableName: PropTypes.string,
  variableUnit: PropTypes.string,
  showEmpty: PropTypes.bool,
  variableTimeDependent: PropTypes.number.isRequired,
  idsTypeId: PropTypes.string,
  groupedEntryData: PropTypes.arrayOf(PropTypes.shape({
    variableValue: PropTypes.string,
    time: PropTypes.number
  })).isRequired
};

export default ExperimentVariableContainer;
